import React from 'react';
import Logotype from '../Logotype/Logotype';
import Navigation from '../Navigation/Navigation';

import './styles.scss';

export default class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isToggleOn: false
        };

        this.toggleClick = this.toggleClick.bind(this);
    }

    toggleClick(event) {
        event.preventDefault();

        this.setState(state => ({
            isToggleOn: !state.isToggleOn
        }));
    }

    render() {
        return (
            <header className={`${this.state.isToggleOn && 'is-open'} view-header ${this.props.headStyle && 'view-header--' + this.props.headStyle}`}>
                <div className="view-container view-header__container">
                    <Logotype className="view-header__logotype" />
                    {(this.props.headStyle !== 'cleared') && 
                        <React.Fragment>
                            <Navigation 
                                className="view-header__nav"
                            />
                            <div className="view-header__undernav" onClick={this.toggleClick}></div>
                            <div className="view-header__burger" onClick={this.toggleClick}>
                                <svg width="35" height="21" viewBox="0 0 35 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <line y1="0.5" x2="35" y2="0.5" stroke="black"/>
                                    <line y1="10.5" x2="35" y2="10.5" stroke="black"/>
                                    <line y1="20.5" x2="35" y2="20.5" stroke="black"/>
                                </svg>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </header>
        )
    }
}