import React from 'react';
import Logotype from '../Logotype/Logotype';
import Navigation from '../Navigation/Navigation';
import FooterOptNav from './FooterOptNav';
import FooterSocial from './FooterSocial';

import './styles.scss';

export default function Footer(props) {
    return(
        <footer className={`view-footer ${props.footerStyle && 'view-footer--' + props.footerStyle }`}>
            <div className="view-container">
                <div className="view-footer__header">
                    <Logotype className="view-footer__logotype" />
                    <Navigation className="view-footer__nav" />
                </div>

                <div className="view-footer__bottom">
                    <div className="view-footer__copyright">
                        © 2021 All rights reserved
                    </div>

                    <div className="view-footer__bottom__right">
                        <FooterOptNav />
                        <FooterSocial />
                    </div>
                </div>
            </div>
        </footer>
    );
}