import React from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';

export default function Layout({headStyle, footerStyle, children }) {
  return (
    <div>
      <div className="view-wrapper">
        <Header headStyle={headStyle} />
        {children}
        <Footer footerStyle={footerStyle} />
      </div>
    </div>
  );
}
