import React from 'react';
import InlineSVG from 'svg-inline-react';

export default function FooterSocial() {
    const socialItems = [
        {
            title: 'Facebook',
            image: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="14.5" stroke="#222222"/>
            <path d="M17.7364 8.00291L16.0574 8C14.1711 8 12.9521 9.35233 12.9521 11.4454V13.034H11.264C11.1181 13.034 11 13.1619 11 13.3196V15.6213C11 15.779 11.1183 15.9067 11.264 15.9067H12.9521V21.7145C12.9521 21.8723 13.0703 22 13.2161 22H15.4187C15.5646 22 15.6827 21.8721 15.6827 21.7145V15.9067H17.6565C17.8024 15.9067 17.9205 15.779 17.9205 15.6213L17.9213 13.3196C17.9213 13.2439 17.8935 13.1713 17.844 13.1177C17.7946 13.0641 17.7272 13.034 17.6572 13.034H15.6827V11.6873C15.6827 11.0401 15.8253 10.7115 16.6051 10.7115L17.7361 10.7111C17.8819 10.7111 18 10.5832 18 10.4256V8.28838C18 8.13094 17.882 8.0032 17.7364 8.00291Z" fill="#222222"/>
            </svg>`,
            link: 'https://facebook.com/gidno.ukraine'
        }, {
            title: 'Twitter',
            image: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="14.5" stroke="#222222"/>
            <path d="M24 9.65738C23.3678 9.93846 22.6942 10.1248 21.9919 10.2152C22.7144 9.778 23.2658 9.09092 23.5251 8.26277C22.8514 8.66985 22.1077 8.95738 21.3151 9.11785C20.6754 8.42754 19.7638 8 18.7693 8C16.8398 8 15.2864 9.58738 15.2864 11.5334C15.2864 11.8134 15.3098 12.0826 15.3672 12.3389C12.4697 12.1957 9.90594 10.7882 8.18362 8.644C7.88294 9.17277 7.70656 9.778 7.70656 10.4295C7.70656 11.6529 8.32812 12.7374 9.25463 13.3652C8.69469 13.3545 8.14538 13.1897 7.68 12.9302C7.68 12.9409 7.68 12.9549 7.68 12.9689C7.68 14.6855 8.88806 16.1114 10.4722 16.4398C10.1886 16.5185 9.87937 16.5562 9.5585 16.5562C9.33538 16.5562 9.11012 16.5432 8.89869 16.4958C9.35025 17.8948 10.6316 18.9232 12.1553 18.9566C10.9695 19.8968 9.46394 20.4632 7.83406 20.4632C7.54825 20.4632 7.27412 20.4503 7 20.4148C8.54381 21.4238 10.3734 22 12.3465 22C18.7597 22 22.266 16.6154 22.266 11.948C22.266 11.7918 22.2607 11.6411 22.2533 11.4914C22.9449 10.9938 23.5261 10.3725 24 9.65738Z" fill="#222222"/>
            </svg>`,
            link: 'https://twitter.com/'
        }
    ];
    return (
        <nav className="view-footer__social">
            <ul className="view-footer__social__list">
                {socialItems.map((item, key) => {
                    return (
                        <li 
                            key={key}
                            className="view-footer__social__item"
                        >
                            <a href={item.link} target="_blank" rel="noreferrer" title={item.title}>
                                <InlineSVG src={ item.image } />
                            </a>
                        </li>
                    )
                })}
            </ul>
        </nav>
    );
}