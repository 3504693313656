import React from 'react';
import { Link } from 'gatsby';

export default function Navigation(props) {
    const navItems = [
        {
            title: 'Про нас',
            link: '/#about'
        }, {
            title: 'Блог',
            link: '/posts/'
        }, {
            title: 'Аналітика',
            link: '/map/'
        }, {
            title: 'Методика',
            link: '/manual/'
        }, {
            title: 'Контакти',
            link: '/contacts/'
        }, {
            title: 'API',
            link: 'https://api.gidno.org/docs'
        }
    ];
    return(
        <nav className={props.className}>
            <ul className={props.className + '__list'}>
                {navItems.map((item, key) => {
                    return (
                        <li 
                            key={key}
                            className={props.className + '__item'}
                        >
                            <Link to={item.link}>
                                { item.title }
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </nav>
    );
}