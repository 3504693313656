import React from 'react';
import { Link } from 'gatsby';
import LogoImage from './gidno-logo.svg';

export default function Logotype(props) {
    return(
        <Link 
            to="/"
            className={props.className}
        >
            <LogoImage />
        </Link>
    );
}