import React from 'react';
import { Link } from 'gatsby';

export default function FooterOptNav() {
    const navItems = [
        {
            title: 'Privacy Policy',
            link: '/privacy-policy'
        }, {
            title: 'Terms and conditions',
            link: '/terms'
        }
    ];
    return (
        <nav className="view-footer__opt-nav">
            <ul className="view-footer__opt-nav__list">
                {navItems.map((item, key) => {
                    return (
                        <li 
                            key={key}
                            className="view-footer__opt-nav__item"
                        >
                            <Link to={item.link}>
                                { item.title }
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </nav>
    );
}